import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TranslocoService } from '@jsverse/transloco';
import { ToastrService } from 'ngx-toastr';
import { zip } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, take } from 'rxjs/operators';
import dayjs from 'dayjs';

import { ReportService } from './report.service';
import { MenuService } from './menu.service';
import { BudgetSpendService } from './budgetSpend.service';
import { AcquisitionService } from './acquisition.service';
import { ReportManagementService } from './reportManagement.service';
import { H } from 'highlight.run';
import { FeatureFlagService } from './feature-flag.service';

export type User = {
    id: number;
    token: string;
    loginDate: Date;
    accountId: number;
    userName: string;
    email: string;
    contactEmail: string;
    roleid: number;
    timeZone: string;
    language: string;
    phone: number;
    accountOptions: { allLibraries: number };
    accountEnabled: number;
    access: {
        demoData: string;
        editTargets: boolean;
        testPermission: string;
        recommendationAdmin: boolean;
        branchLibrary: number[];
        weedingTool: boolean;
        metricsEnter: boolean;
        metricsIQEnter: boolean;
        metricsCMTEnter: boolean;
        metricsLock: boolean;
        metricsAdmin: boolean;
        metricsUpload: boolean;
        censusData: boolean;
        selectForCart: boolean;
        bibzAccess: boolean;
        bibzAccount: { userName: string; password: string }[];
        manageArticles: boolean;
        manageFeatureSpotlight: boolean;
        favoritePages: string;
        displayPopUp: number;
        addCustomReports: boolean;
        metricsMapping: boolean;
        balanceAdmin: boolean;
        balanceUser: boolean;
        demographicsUser: boolean;
        weedV2: boolean;
        justWeedMenu: boolean;
        inventoryModule: boolean;
        selectPreferredLanguage: boolean;
        scheduleReports: boolean;
        createReportTemplates: boolean;
        branchAdmin: boolean;
        metricsDashboard: boolean;
    };
    permissions: [{ [key: string]: string }];
    lastLogin: Date;
};

@Injectable({ providedIn: 'root' })
export class AuthService {
    apiToken = '';
    user: User = null;
    sessionTimer;
    localMode = false;
    onUserLogin = new EventEmitter();

    constructor(
        private menuService: MenuService,
        private toastrService: ToastrService,
        private reportService: ReportService,
        private router: Router,
        private http: HttpClient,
        private budgetSpendService: BudgetSpendService,
        private acqService: AcquisitionService,
        private reportManagementService: ReportManagementService,
        private transloco: TranslocoService,
        private featureFlags: FeatureFlagService
    ) {}

    getUser(): User {
        if (!this.user) {
            this.redirectToLogin();
        }

        return this.user;
    }

    loggedIn(): boolean {
        return !(this.user.userName === undefined);
    }

    redirectToLogin(): void {
        this.router.navigateByUrl('/pages/login');
    }

    redirectToHome(): void {
        this.router.navigateByUrl('/');
    }

    checkSession(): void {
        if (this.apiToken === '') {
            this.redirectToLogin();
            return;
        }
        this.http.get('api/login/validSession', {
            params: {
                userid: this.user.id.toString(),
                token: this.apiToken
            }
        }).subscribe(() => { }, () => {
            this.redirectToLogin();
        });

        if (!this.sessionTimer) {
            this.sessionTimer = setTimeout(() => { this.checkSession(); }, 300000);
        }
    }

    logUserToHighlight(): void {
        H.identify(this.user.email, {
            id: this.user.id,
            name: this.user.userName,
            library: this.reportService?.getCurrentLibrary()?.name,
            branch: this.reportService?.getBranchName()
        });
    }

    reConnect(): boolean {
        if (localStorage['userSession'] === undefined) {
            return false;
        }
        this.localMode = ['localhost', '127.0.0.1'].includes(location.hostname);
        if (this.user && this.apiToken.length > 0) {
            if (!this.reportService.currentLibrary) {
                this.reportService.loadLibraries();
            }
            if (!this.localMode) this.logUserToHighlight();
            return true;
        }
        if (localStorage['userSession'] === undefined) return false;
        this.user = JSON.parse(localStorage['userSession']);
        if (!this.user) return false;
        this.apiToken = this.user.token;
        if (this.user.userName === undefined || this.apiToken.length < 1) return false;

        this.transloco.setActiveLang(this.user.language || 'en');
        this.userLog('Reconnected from Local Storage');

        if (!this.reportService.currentLibrary) {
            this.reportService.loadLibraries();
        }
        if (!this.localMode) this.logUserToHighlight();
        this.checkSession();
        this.processPermissions();
        return true;
    }

    processPermissions(): void {
        this.user.access = {
            demoData: '',
            editTargets: false,
            testPermission: '',
            recommendationAdmin: false,
            branchLibrary: [],            
            weedingTool: false,
            metricsEnter: false,
            metricsIQEnter: false,
            metricsCMTEnter: false,
            metricsAdmin: false,
            metricsUpload: false,
            metricsLock: false,
            metricsMapping: false,
            censusData: false,
            selectForCart: false,
            bibzAccess: false,
            bibzAccount: [],
            manageArticles: false,
            manageFeatureSpotlight: false,
            favoritePages: '',
            displayPopUp: 0,
            addCustomReports:false,
            balanceAdmin: false,
            balanceUser: false,
            demographicsUser: false,
            weedV2: false,
            justWeedMenu: false,
            inventoryModule: false,
            selectPreferredLanguage: false,
            scheduleReports: false,
            createReportTemplates: false,
            branchAdmin: false,
            metricsDashboard: false
        };

        if (this.user.permissions) {
            const booleanPermissions = [
                'editTargets', 'recommendationAdmin', 'weedingTool', 'selectForCart', 'bibzAccess', 'metricsEnter', 'metricsIQEnter',
                'metricsCMTEnter', 'metricsUpload', 'metricsAdmin', 'metricsLock', 'manageArticles', 'addCustomReports', 'metricsMapping',
                'balanceAdmin', 'balanceUser', 'demographicsUser', 'weedV2', 'justWeedMenu',
                'inventoryModule', 'selectPreferredLanguage', 'scheduleReports', 'manageFeatureSpotlight', 'createReportTemplates',
                'branchAdmin', 'metricsDashboard'
            ];

            this.user.permissions.forEach(permission => {
                if (booleanPermissions.includes(Object.keys(permission)[0])) {
                    if (permission[Object.keys(permission)[0]] === '1')
                        this.user.access[Object.keys(permission)[0]] = true;
                    return;
                }
                if (['branchLibrary', 'bibzAccount','displayPopUp'].includes(Object.keys(permission)[0])) {
                    if (Object.keys(permission)[0] === 'bibzAccount') {
                        this.user.access.bibzAccount = JSON.parse(permission['bibzAccount']);
                    }
                    if (Object.keys(permission)[0] === 'branchLibrary' && permission['branchLibrary'] !== '0' ) {
                        permission['branchLibrary'].split(',').forEach(branch => {
                            if (!isNaN(parseInt(branch, 10))) this.user.access.branchLibrary.push(parseInt(branch, 10));
                        });
                        this.reportService.validBranch = this.user.access.branchLibrary; // for branch library access
                    }
                    if (Object.keys(permission)[0] === 'displayPopUp') {
                        this.user.access.displayPopUp = parseInt(permission['displayPopUp'],10);
                    }
                } else {
                    this.user.access[Object.keys(permission)[0]] = permission[Object.keys(permission)[0]];
                }
            });
        }

        const savedLibrary = parseInt(localStorage.getItem('currentLibrary' + this.user.id), 10);
        const savedBranch = localStorage.getItem('currentBranch' + this.user.id);

        if (isNaN(savedLibrary) || savedLibrary < 1 || savedBranch.length < 1) {
            localStorage.removeItem('currentLibrary'+this.user.id);
            localStorage.removeItem('currentBranch' + this.user.id);
        }

        if (savedLibrary > 0) {
            // wait until libraries have loaded to set them
            this.reportService.$libraries.pipe(
                filter(libraries => libraries.length > 0),
                take(1),
                switchMap(() => {
                    this.reportService.changeLibrary({ id: savedLibrary });
                    return this.reportService.monitorChange;
                }),
                // there is another call that is updating the library and branch also
                // happening. wait until the current library is the saved one to 
                // continue. LIQ-458 and LIQ-492 would fix these race conditions
                filter(({currentLibrary}) => currentLibrary === savedLibrary),
                take(1)
            ).subscribe(() => {
                this.reportService.currentBranch = savedBranch;
                this.reportService.changeBranch();
            });
        }

        this.menuService.refreshMenu();
        this.setUserMenuVisibility();
    }

    setUserMenuVisibility(): void {
        if (!this.user.access.metricsEnter && !this.user.access.metricsAdmin && !this.user.access.metricsCMTEnter
            && !this.user.access.metricsIQEnter && !this.user.access.metricsLock) {
            this.menuService.setMenuVisibility('enterMetrics', false);
        }

        if (!this.user.access.balanceUser) {
            this.menuService.setMenuVisibility('balance', false);
        }

        if (!this.user.access.inventoryModule) {
            this.menuService.setMenuVisibility('inventory', false);
        }

        if (!this.user.access.balanceUser ) {
            this.menuService.setMenuVisibility('balanceReport', false);
        }

        if (!this.user.access.demographicsUser) {
            this.menuService.setMenuVisibility('demographics', false);
        }

        if (!this.user.access.weedV2) {
            this.menuService.setMenuVisibility('reportBuilder', false);
            this.menuService.setMenuVisibility('inventory', false);
        }

        if (!this.user.access.weedingTool) {
            this.menuService.setMenuVisibility('weedingTool', false);
        }

        if (this.user.access.justWeedMenu) {
            this.menuService.switchToWeedingMenu();
        }

        this.featureFlags.hasFeature('metrics-dashboard').subscribe(enabled => {
            this.menuService.setMenuVisibility('metricsDashboard', enabled && this.user?.access.metricsDashboard);
        });

        this.featureFlags.hasFeature('shelving-allocation').subscribe(enabled => {
            this.menuService.setMenuVisibility('shelvingAllocation', enabled);
        });

        this.reportService.monitorChange.pipe(
            distinctUntilChanged((prev, curr) => (
                // only continue if the branch or the library actually changed
                prev.currentBranch === curr.currentBranch &&
                prev.currentLibrary === curr.currentLibrary
            ))
        ).subscribe(item => {
            if (!item.currentBranch || !item.currentLibrary) return;
            localStorage.setItem('currentLibrary' + this.user.id, item.currentLibrary.toString());
            localStorage.setItem('currentBranch' + this.user.id, item.currentBranch.toString());

            if (item.currentLibrary !== 92) {
                this.menuService.setMenuVisibility('physicalCollectionByFindIt', false);
            } else {
                this.menuService.setMenuVisibility('physicalCollectionByFindIt', true);
            }

            if (item.currentLibrary !== 245 && item.currentLibrary !== 668) {
                this.menuService.setMenuVisibility('physicalCollectionByLLC', false);
            } else {
                this.menuService.setMenuVisibility('physicalCollectionByLLC', true);
            }

            if (this.reportService.getBranchVenueID()) {
                this.menuService.setMenuVisibility('footTraffic', true);
            }

            if (
                this.reportService.branches.length < 2 &&
                (!this.reportService.currentConsortium && !this.reportService.currentAccountGroup)
            ) {
                this.menuService.setMenuVisibility('physicalCircDetailByLocation', false);
                this.menuService.setMenuVisibility('physicalCollectionByBranch', false);
                this.menuService.setMenuVisibility('balanceReport', false);
                if (location.href.indexOf('locationCirculation') !== -1 ||
                    location.href.indexOf('collectionBranch') !== -1) {
                    this.router.navigateByUrl('/pages/landingPage');
                }
            } else {
                this.menuService.setMenuVisibility('physicalCircDetailByLocation', true);
                this.menuService.setMenuVisibility('physicalCollectionByBranch', true);
                this.menuService.setMenuVisibility('balanceReport', true);
            }
            if (this.reportService.inConsortiumID) 
                this.menuService.setMenuVisibility('physicalCircDetailByLocation', true);
            this.menuService.setMenuVisibility('collectionAcquire', false);
            this.menuService.setMenuVisibility('budgetSpending', false);
            this.menuService.setMenuVisibility('titlesOrdered', false);
            this.menuService.setMenuVisibility('openOrders', false);
            this.menuService.setMenuVisibility('invoices', false);
            zip([
                this.budgetSpendService.getBudgetSpend(),
                this.acqService.getTitlesOrdered(dayjs().subtract(3, 'months').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD'))
            ]).subscribe(([budgetRes, polarisRes]) => {
                const budget = !!budgetRes.data.length;
                const polaris = !!polarisRes.data.length;

                if (budget || polaris) {
                    this.menuService.setMenuVisibility('collectionAcquire', true);
                }

                if (budget) {
                    this.menuService.setMenuVisibility('budgetSpending', true);
                }

                if (polaris) {
                    this.menuService.setMenuVisibility('titlesOrdered', true);
                    this.menuService.setMenuVisibility('openOrders', true);
                    this.menuService.setMenuVisibility('invoices', true);
                }
            });
        
            this.reportManagementService.getAllReports().subscribe(result => { 
                if (result.data.length === 0) { 
                    this.menuService.setMenuVisibility('customReports', false);
                } else {
                    this.menuService.setMenuVisibility('customReports', true);
                }
            });
        });
    }

    public logout(): boolean {
        clearTimeout(this.sessionTimer);
        this.sessionTimer = null;
        if (this.user) {
            this.http.post('/api/login/logoutUser', {
                userid: this.user.id,
                token: this.apiToken
            }).subscribe();
        }
        this.apiToken = '';
        this.user = null;
        this.reportService.currentLibrary = null;
        this.reportService.lastLibrary = null;
        this.reportService.monitorChange.next({ currentLibrary: null, currentBranch: null });

        localStorage.removeItem('userSession');
        this.menuService.refreshMenu();
        this.redirectToLogin();
        this.toastrService.info('pages.login.toasts.logoutSuccess');
        this.userLog('Logged Out');
        return true;
    }

    public userLog(message: string): void {
        if (!this.user || !this.user.userName || !message || !this.http) {
            if (message.indexOf('Library Health') === -1) return;
        }
        this.http.post('/api/login/userLog', {
            user: this.user?.userName ? this.user.userName : 'anonymous',
            message: message,
            libraryid: this.reportService.currentLibrary,
            branchid: this.reportService.currentBranch
        }).subscribe({
            error: (e) => {return console.error(e);}
        });
    }
}
